import React, { Fragment, useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
import { Link } from "gatsby";
import logo from "../../assets/images/Psychedelics-Text.svg";

const Header = () => {
  const [collapseOpen, setCollapseOpen] = useState(false);

  return (
    <Fragment>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onKeyDown={e => console.log("keydown")}
          role="button"
          aria-label="Collapse Menu"
          tabIndex={0}
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="fixed-top p-0 bg-primary" expand="lg">
        <Container className="d-inline-flex">
          <div className="navbar-translate">
            <NavbarBrand tag="span">
              <Link to="/" className="text-decoration-none">
                <NavLink tag="span" className="bg-white pr-0 pl-0 header-logo">
                  <img src={logo} alt="Logo" />
                </NavLink>
              </Link>
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <Link to="/about">
                  <NavLink tag="span" className="pr-2 pl-2">
                    {/* <i className="now-ui-icons travel_info transparent pr-1"></i> */}
                    <p className="ml-0">About</p>
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/people">
                  <NavLink tag="span" className="pr-2 pl-2">
                    {/* <i className="now-ui-icons users_circle-08 transparent pr-1"></i> */}
                    <p className="ml-0 text-nowrap">Key People</p>
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/news">
                  <NavLink tag="span" className="pr-2 pl-2">
                    {/* <i className="now-ui-icons business_globe transparent pr-1"></i> */}
                    <p className="ml-0">News</p>
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/freedom-of-thought">
                  <NavLink tag="span" className="pr-2 pl-2">
                    {/* <i className="now-ui-icons users_circle-08 transparent pr-1"></i> */}
                    <p className="ml-0">Freedom of Thought</p>
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/services">
                  <NavLink tag="span" className="pr-2 pl-2">
                    {/* <i className="now-ui-icons business_briefcase-24 transparent pr-1"></i> */}
                    <p className="ml-0">Services</p>
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/documents">
                  <NavLink tag="span" className="pr-2 pl-2">
                    {/* <i className="now-ui-icons files_paper transparent pr-1"></i> */}
                    <p className="ml-0">Documents</p>
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/blog">
                  <NavLink tag="span" className="pr-2 pl-2">
                    {/* <i className="now-ui-icons design_bullet-list-67 transparent pr-1"></i> */}
                    <p className="ml-0">Blog</p>
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/contact">
                  <NavLink tag="span" className="pr-2 pl-2">
                    {/* <i className="now-ui-icons ui-1_email-85 transparent pr-1"></i> */}
                    <p className="ml-0">Contact</p>
                  </NavLink>
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </Fragment>
  );
};

export default Header;
