import React from "react";
import "./src/assets/styles/bootstrap.min.css";
// import "./src/assets/styles/bootstrap.min.copy.css";

import "./src/assets/styles/now-ui-kit.css";
import "./src/assets/styles/nucleo-icons-page-styles.css";
import "./src/assets/styles/styles.css";

import Layout from "./src/components/Layout";

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}
