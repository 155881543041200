import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTwitter,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";
import { OutboundLink } from "gatsby-plugin-google-gtag"

function Footer() {
  return (
    <footer className="footer trajan" data-background-color="black">
      <Container className="text-center">
        <Row>
          <Col className="col-4 text-left">
            <Link 
              to="/legalnotice"
              className="footer legal"
            >
              Legal Notice
            </Link>
          </Col>
          <Col className="col-4 copyright float-none text-center" id="copyright">
            © {new Date().getFullYear()}, Cannabis and Psychedelics Law Group LLP
          </Col>
          <Col className="col-4 text-right">
            <OutboundLink
              className="text-right"
              href="https://www.twitter.com/canpsylaw/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faTwitter}
                size="1x"
                className="mr-2"
              />
            </OutboundLink>
            <OutboundLink
              className="text-right"
              href="https://www.instagram.com/canpsylaw/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                size="1x"
                className="mr-2"
              />
            </OutboundLink>
            <OutboundLink
              className="text-right"
              href="https://www.facebook.com/canpsylaw/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebookSquare}
                size="1x"
                className="mr-2"
              />
            </OutboundLink>
          </Col>
          </Row>
        {/* <div className="copyright float-none" id="copyright">
          © {new Date().getFullYear()}, Cannabis and Psychedelics Law Group LLP
        </div> */}
      </Container>
    </footer>
  );
}

export default Footer;
